<template>
  <div class="magazine-page">
    <div class="contents">
      <p class="desc">이모션글로벌의 시선으로<br />세상을 이야기합니다.</p>
    </div>
    <ul class="magazine-list">
      <li v-for="(item, index) in listData" :key="item.id">
        <router-link :to="`/magazine/${item.id}/?list=${totalLength - index}`">
          <span class="list-num">{{ totalLength - index }}</span>
          <div class="list-thumb">
            <img :src="imgUrl(item.moKeyPicturePhysicalName)" :alt="item.magazineTitle" />
          </div>
          <div class="list-cont">
            <i></i>
            <div class="list-cont-tit" v-html="handleNewLine(item.magazineTitle)"></div>
            <div class="list-cont-date">{{ item.reportingDate | dateFormat }}</div>
            <p class="list-cont-desc" v-html="handleNewLine(item.magazineDesc)"></p>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
  import {mapGetters} from "vuex";
  import {apiMagazineList} from "@/api";
  import {commonMethods} from "@/utils/common-methods";
  export default {
    name: "magazineList",
    data() {
      return {
        page: 0,
        size: 10,
        totalPage: 0,
        isInit: false,
        totalLength: 0,
        keyword: "",
        scene: null,
        listData: [],
        loading: false
      };
    },
    computed: {
      ...mapGetters(["GE_CONT_HEIGHT"])
    },
    watch: {
      GE_CONT_HEIGHT() {}
    },
    mixins: [commonMethods],
    methods: {
      infiniteScroll() {
        if (
          this.page < this.totalPage &&
          !this.loading &&
          window.innerHeight + window.scrollY >= document.body.offsetHeight
        ) {
          this.page = this.page + 1;
          this.fetchData();
        }
      },
      async fetchData() {
        this.loading = true;
        try {
          const {
            data: {data: response}
          } = await apiMagazineList({
            page: this.page,
            size: this.size,
            keyword: this.keyword
          });
          this.totalPage = response.totalPages;
          this.listData = this.listData.concat(response.content);

          if (!this.isInit) {
            this.totalLength = parseInt(response.totalElements);
            this.isInit = true;
          }
          this.loading = false;
        } catch (error) {
          this.loading = false;
          console.error(error);
        }
      }
    },
    created() {
      this.fetchData();
      window.addEventListener("scroll", this.infiniteScroll);
    },
    destroyed() {
      window.removeEventListener("scroll", this.infiniteScroll);
    }
  };
</script>
<style lang="scss" scoped>
  .magazine-page {
    padding: 111px 0 70px;
    .contents {
      position: relative;
      &::before {
        display: block;
        content: "";
        position: absolute;
        left: 162px;
        bottom: -46px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #ee2c3c;
      }
    }
    .desc {
      font-weight: 700;
      font-size: 30px;
      line-height: 43px;
      letter-spacing: -0.03em;
    }
  }
  .magazine-list {
    margin-top: 50px;
    padding: 0 20px;
    li {
      overflow: hidden;
      &:last-child {
        margin-right: 0;
      }
      a {
        display: block;
      }
      .list-num {
        display: block;
        @include montserrat;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: -0.05em;
      }
      .list-thumb {
        display: block;
        margin: 27px 0 0;
        aspect-ratio: 1/1;
        overflow: hidden;
        border-radius: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .list-cont {
        position: relative;
        margin: 20px 0 0;
        padding: 24px 0 0;

        i {
          position: absolute;
          top: 0;
          left: 0;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #ee2c3c;
        }

        &-tit {
          font-weight: 700;
          font-size: 18px;
          line-height: 29px;
          letter-spacing: -0.05em;
          color: #000;
        }

        &-date {
          @include montserrat;
          margin: 32px 0 0;
          font-weight: 300;
          font-size: 11px;
          letter-spacing: -0.05em;
          color: #b7b7b7;
        }

        &-desc {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
          margin: 9px 0 0;
          font-size: 14px;
          line-height: 23px;
          letter-spacing: -0.03em;
          color: #000;
        }
      }
    }
    li + li {
      margin-top: 50px;
    }
  }
</style>
